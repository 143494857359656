/* eslint-disable semi */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */
// eslint-disable-next-line no-unused-vars

var PageSource = /** @class */ (function () {
	function PageSource(cookie) {
		this.cookie = cookie;
	}
	PageSource.prototype.saveToLocal = function (url) {
		var cookie = this.cookie;
		if (url) {
			var referrer = cookie.getCookie();
			if (!referrer) {
				cookie.setCookie(url);
			}
		}
		else {
			var referrer = cookie.getCookie();
			if (!referrer) {
				if (document.referrer) {
					cookie.setCookie(document.referrer);
				}
				else {
					cookie.setCookie(null);
				}
			}
		}
	};
	PageSource.prototype.takeToLocal = function () {
		return this.cookie.getCookie();
	};
	PageSource.prototype.removeToLocal = function () {
		var url = this.cookie.getCookie();
		this.cookie.clearCookie();
		return url;
	};
	return PageSource;
}());

export {
	PageSource
}
