/* eslint-disable semi */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-var */
/**
 * @name HTMLElement 轻量级抽象库
 */
var HElement = /** @class */ (function () {
	function HElement(eleName) {
		this.eles = [];
		this.length = 0;
		var getHtmlElements = function (eleName) {
			if (!eleName)
				return [];
			var eles = [];
			//接收的document类型(可扩展)
			var char = eleName.substring(0, 1);
			var charV = eleName.substring(1, eleName.length);
			if (char === '#') {
				var ele = document.getElementById(charV);
				if (ele) {
					eles.push(document.getElementById(charV));
				}
			}
			else if (char === '.') {
				var data = document.getElementsByClassName(charV), len = data.length;
				for (var i = 0; i < len; i++) {
					eles.push(data.item(i));
				}
			}
			return eles;
		};
		var name;
		var eles = [];
		if (typeof eleName === 'string') {
			name = eleName;
			eles = getHtmlElements(eleName);
		}
		else if (eleName instanceof Array) {
			eles = eleName;
		}
		else {
			if (undefined === eleName) {
				this.length = 0;
				return;
			}
			name = eleName.id;
			eles.push(eleName);
		}
		if (eles && eles.length) {
			this.length = eles.length;
			this.eles = eles;
		}
	}
	HElement.prototype.addClass = function (clasName) {
		var eles = this.eles;
		if (eles && eles.length) {
			var keyName = clasName || null;
			if (keyName && keyName.trim()) {
				var data = eles;
				data.forEach(function (v) {
					var arr = v.className.split(" ") || [], len_2 = arr.length;
					for (var i_2 = 0; i_2 < len_2; i_2++) {
						var item = arr[i_2];
						if (item == clasName) {
							delete arr[i_2];
							break;
						}
					}
					// 添加
					arr.push(clasName);
					v.className = arr.join(" ").trim();
				});
			}
		}
		return this;
	};
	HElement.prototype.removeClass = function (clasName) {
		var eles = this.eles;
		if (eles && eles.length) {
			var keyName = clasName || null;
			if (keyName && keyName.trim()) {
				var data = eles;
				data.forEach(function (v) {
					var arr = v.className.split(" ") || [], len_2 = arr.length;
					for (var i = 0; i < len_2; i++) {
						var item = arr[i];
						if (item == clasName) {
							delete arr[i];
							v.className = arr.join(" ").trim();
							// 取值为空删除节点属性
							if ("" === v.className.trim() && v.hasAttribute("class") && v.removeAttributeNode) {
								v.removeAttributeNode(v.getAttributeNode("class"));
							}
							break;
						}
					}
				});
			}
			else {
				eles.forEach(function (v) {
					v.className = "";
				});
			}
		}
		return this;
	};
	HElement.prototype.html = function (html) {
		var eles = this.eles;
		if (eles && eles.length) {
			if (html || html === '' || html === "") {
				eles.forEach(function (v) {
					v.innerHTML = html;
				});
			}
			else {
				return eles[0].innerHTML;
			}
		}
		return this;
	};
	HElement.prototype.text = function (text) {
		var eles = this.eles;
		if (text || text === '' || text === "") {
			eles.forEach(function (v) {
				v.innerText = text;
			});
			return this;
		}
		else {
			return eles[0].innerText;
		}
	};
	HElement.prototype.style = function (propName, value) {
		var eles = this.eles;
		if (eles && eles.length) {
			if (undefined === value) {
				return eles[0].style.getPropertyValue(propName);
			}
			else {
				eles.forEach(function (v) {
					if (!v.hasAttribute('style')) {
						v.setAttribute('style', '');
					}
					v.style.setProperty(propName, value);
				});
			}
		}
		return this;
	};
	HElement.prototype.removeStyle = function (propertyName) {
		var eles = this.eles;
		if (eles && eles.length) {
			if (undefined === propertyName) {
				eles.forEach(function (v) {
					v.setAttribute("style", "");
				});
			}
			else {
				eles.forEach(function (v) {
					v.style.removeProperty(propertyName);
				});
			}
		}
		return this;
	};
	HElement.prototype.attr = function (name, value) {
		var eles = this.eles;
		if (undefined === value) {
			return eles[0].getAttribute(name);
		}
		else {
			eles.forEach(function (v) {
				v.setAttribute(name, value);
			});
		}
		return this;
	};
	HElement.prototype.removeAttr = function (name) {
		var eles = this.eles;
		eles.forEach(function (v) {
			v.removeAttribute(name);
		});
		return this;
	};
	HElement.prototype.show = function () {
		var eles = this.eles;
		eles.forEach(function (v) {
			if (!v.hasAttribute('style')) {
				v.setAttribute('style', '');
			}
			v.style.setProperty('display', 'block');
		});
		return this;
	};
	HElement.prototype.hide = function () {
		var eles = this.eles;
		eles.forEach(function (v) {
			if (!v.hasAttribute('style')) {
				v.setAttribute('style', '');
			}
			v.style.setProperty('display', 'none');
		});
		return this;
	};
	HElement.prototype.hidden = function () {
		var eles = this.eles;
		eles.forEach(function (v) {
			if (!v.hasAttribute('style')) {
				v.setAttribute('style', '');
			}
			v.style.setProperty('visibility', 'hidden');
		});
		return this;
	};
	HElement.prototype.visible = function () {
		var eles = this.eles;
		eles.forEach(function (v) {
			if (!v.hasAttribute('style')) {
				v.setAttribute('style', '');
			}
			v.style.setProperty('visibility', 'visible');
		});
		return this;
	};
	HElement.prototype.hasClass = function (clasName) {
		var name = this.eles[0].className || "";
		if (clasName && name) {
			return name.indexOf(clasName) === -1 ? false : true;
		}
		else {
			return false;
		}
	};
	HElement.prototype.children = function (keyword) {
		var eles = this.eles, retuList = [], tagName, className;
		if (keyword) {
			keyword = keyword.replace(/\s/g, "");
			tagName = keyword.toUpperCase(), className = "." === keyword.substr(0, 1) ? keyword.substr(1, keyword.length) : "";
		}
		eles.forEach(function (v) {
			var data = v.childNodes, len = data.length, item;
			// nodeType
			for (var i = 0; i < len; i++) {
				item = data[i];
				if (item.nodeType === 1) {
					// all children node
					if (undefined === keyword) {
						retuList.push(item);
					}
					// className
					else if (className) {
						if (item.className && className === item.className) {
							retuList.push(item);
						}
					}
					// assign type node
					else {
						// tag name
						if (tagName === item.tagName) {
							retuList.push(item);
						}
					}
				}
			}
		});
		return new HElement(retuList);
	};
	HElement.prototype.eq = function (index) {
		return new HElement(this.eles[index]);
	};
	HElement.prototype.get = function (index) {
		if (undefined !== index) {
			return this.eles[index];
		}
		else {
			return this.eles;
		}
	};
	HElement.prototype.eqAll = function () {
		var _this = this;
		var eles = [];
		this.eles.forEach(function (v, i) {
			eles.push(new HElement(_this.eles[i]));
		});
		return eles;
	};
	HElement.prototype.getAll = function () {
		return this.eles;
	};
	HElement.prototype.find = function (keyword) {
		var htmls = [];
		// 属性
		if (keyword.substr(0, 1) === "[") {
			var arr = keyword.replace("[", "").replace("]", "").split("=");
			var attr_1 = arr[0], val_1 = arr[1];
			var eles = this.eles;
			var findAttr_1 = function (ele) {
				var chils = ele.children, len = chils ? chils.length : 0;
				for (var i = 0; i < len; i++) {
					if (chils && chils.length && chils.item) {
						var item = chils.item(i);
						if (item.getAttribute(attr_1) && val_1 === item.getAttribute(attr_1)) {
							htmls.push(item);
						}
						// has children node
						if (item.children.length) {
							findAttr_1(item);
						}
					}
				}
			};
			this.eles.forEach(function (v) {
				findAttr_1(v);
			});
		}
		return new HElement(htmls);
	};
	return HElement;
}());

export {
	HElement
}
