// eslint-disable-next-line no-var
var FuncLock = /** @class */ (function () {
	function FuncLock() {
		// eslint-disable-next-line no-var
		var _this = this;
		this._lock = false;
		this.enable = function (method, failure) {
			if (!_this._lock) {
				_this._lock = true;
				method();
			}
			else {
				failure && failure();
			}
		};
		this.lock = function () {
			_this._lock = true;
		};
		this.clear = function () {
			_this._lock = false;
		};
	}
	return FuncLock;
}());
export { FuncLock };
