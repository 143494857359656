// eslint-disable-next-line no-var
var SetTimeout = /** @class */ (function () {
	function SetTimeout(time) {
		// eslint-disable-next-line no-var
		var _this = this;
		if (time === void 0) { time = 0; }
		this.enable = function (action) {
			if (_this.timer) {
				clearTimeout(_this.timer);
			}
			// eslint-disable-next-line prefer-arrow-callback
			_this.timer = setTimeout(function () {
				clearTimeout(_this.timer);
				action();
			}, _this.timeOut);
		};
		this.clear = function () {
			if (_this.timer) {
				clearTimeout(_this.timer);
			}
		};
		if (time >= 1) {
			this.timeOut = time;
		}
	}
	return SetTimeout;
}());
export {
	SetTimeout
	// eslint-disable-next-line semi
}
