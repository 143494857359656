// eslint-disable-next-line no-var
var ParseUrl = /** @class */ (function () {
	function ParseUrl(search) {
		this.search = search;
	}
	ParseUrl.prototype.getParam = function () {
		return this.decodeURL();
	};
	ParseUrl.prototype.getDecodeURI = function () {
		return this.decodeURL(decodeURI);
	};
	ParseUrl.prototype.getDecodeURIComponent = function () {
		return this.decodeURL(decodeURIComponent);
	};
	ParseUrl.prototype.decodeURL = function (decode) {
		// eslint-disable-next-line no-var
		var search = this.search;
		if (!decode) {
			decode = function (str) {
				return str;
				// eslint-disable-next-line semi
			};
		}
		//返回当前 URL 的查询部分（问号 ? 之后的部分）。
		// eslint-disable-next-line no-var
		var urlParameters = location.search;
		if (search)
			urlParameters = search;
		//声明并初始化接收请求参数的对象
		// eslint-disable-next-line no-var
		var requestParameters = {};
		//如果该求青中有请求的参数，则获取请求的参数，否则打印提示此请求没有请求的参数
		// eslint-disable-next-line no-var
		var idx = urlParameters.indexOf('?');
		if (idx != -1) {
			//获取请求参数的字符串
			// eslint-disable-next-line no-var
			var parameters = urlParameters.substr((idx + 1));
			//将请求的参数以&分割中字符串数组
			// eslint-disable-next-line no-var
			var parameterArray = parameters.split('&');
			//循环遍历，将请求的参数封装到请求参数的对象之中
			// eslint-disable-next-line no-var
			for (var i = 0; i < parameterArray.length; i++) {
				requestParameters[parameterArray[i].split('=')[0]] = decode((parameterArray[i].split('=')[1]));
			}
		}
		return requestParameters;
	};
	return ParseUrl;
}());

export {
	ParseUrl
// eslint-disable-next-line semi
}
