// eslint-disable-next-line no-var
var SetInterval = /** @class */ (function () {
	function SetInterval(time) {
		// eslint-disable-next-line no-var
		var _this = this;
		if (time === void 0) { time = 0; }
		this.enable = function (method) {
			if (_this.timer) {
				clearInterval(_this.timer);
			}
			// eslint-disable-next-line prefer-arrow-callback
			_this.timer = setInterval(function () {
				method();
			}, _this.timeOut);
		};
		this.clear = function () {
			if (_this.timer) {
				clearInterval(_this.timer);
			}
		};
		if (time >= 1) {
			this.timeOut = time;
		}
	}
	return SetInterval;
}());

export {
	SetInterval
	// eslint-disable-next-line semi
}
