// eslint-disable-next-line no-var
var FormatUrl = /** @class */ (function () {
	function FormatUrl(url, params) {
		this.url = url;
		this.params = params;
	}
	FormatUrl.prototype.getURL = function () {
		return this.encodeURL();
	};
	FormatUrl.prototype.getEncodeURI = function () {
		return this.encodeURL(encodeURI);
	};
	FormatUrl.prototype.getEncodeURIComponent = function () {
		return this.encodeURL(encodeURIComponent);
	};
	FormatUrl.prototype.encodeURL = function (encode) {
		// eslint-disable-next-line no-var
		var url = this.url;
		// eslint-disable-next-line no-var
		var params = this.params;
		if (!encode) {
			encode = function (str) {
				return str;
				// eslint-disable-next-line semi
			};
		}
		// 初始化当前参数
		// eslint-disable-next-line no-var
		var charIdx = url.indexOf("?");
		if (charIdx != -1) {
			url = url.substr(0, charIdx);
		}
		// eslint-disable-next-line no-var
		var newUrl = "?";
		if (params) {
			// eslint-disable-next-line no-var
			for (var item in params) {
				if (params.hasOwnProperty(item)) {
					// eslint-disable-next-line no-var
					var element = encode(params[item]);
					// eslint-disable-next-line semi
					newUrl += item + '=' + element + '&';
				}
			}
			url += newUrl.substr(0, newUrl.length - 1);
		}
		return url || null;
	};
	return FormatUrl;
}());

export {
	FormatUrl
};
